import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  getBonafideInsuranceInfo,
  submitBonafideOrder
} from "features/bonafide/actions";
import { InlineWaiting } from "components/ui";
import HasRolePermission from "components/shared/has-role-permission";
import { formatDate } from "utils/dates";
import { formatMoney } from "accounting";
import { MdRefresh, MdBugReport } from "react-icons/lib/md";
import PureModal from "components/ui/modal/pure-modal";
import { useFetch } from "hooks";
import { post, get } from "utils/api";
import { errorMessage } from "actions/message";

function getStatusTextColor() {
  return "";
}

const BonafideInsuranceVerificationPanel = ({
  visible,
  getBonafideInsuranceInfo,
  companyInfo,
  orderId,
  errorMessage
}) => {
  const [index, setIndex] = React.useState(0);
  const initData = async () => {
    await getBonafideInsuranceInfo(orderId);
  };

  React.useEffect(() => {
    initData();
    setIndex(0);
  }, [orderId]);

  const insuranceVerificationAPI = useFetch({
    apiFn: orderId =>
      post(`bonafide/insurance-verification/${orderId}`, { orderId }),
    onSuccess: () => getVerificationInfoAPI.fetchData(orderId),
    onError: err => errorMessage(err.message)
  });

  const getVerificationInfoAPI = useFetch({
    apiFn: orderId => get(`bonafide/verification-info/${orderId}`),
    defaultValue: [],
    onError: err => errorMessage(err.message)
  });
  React.useEffect(() => {
    getVerificationInfoAPI.fetchData(orderId);
  }, [orderId]);

  const verificationHistory = getVerificationInfoAPI.response ?? [];

  const bonafideSelectedResult = verificationHistory?.[index] ?? {};
  if (!companyInfo?.eligible_for_insurance_verification)
    return (
      <div
        className={`bonafide-insurance-verification ${visible ? "" : "hidden"}`}
      >
        <div
          style={{
            padding: 20,
            marginTop: "20px",
            gridColumn: "1/-1",
            fontWeight: "bold",
            color: "#666",
            fontSize: "1rem"
          }}
        >
          Not Eligible for Insurance Verification
        </div>
      </div>
    );
  return (
    <div
      className={`bonafide-insurance-verification ${visible ? "" : "hidden"}`}
    >
      <div
        className="bonafide-panel-header"
        style={{
          padding: 5,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gridGap: 10,
          alignItems: "center",
          borderBottom: "1px solid #ccc"
        }}
      >
        <label>History: </label>
        <select onChange={e => setIndex(e.target.value)} style={{ margin: 0 }}>
          {verificationHistory?.map((x, index) => (
            <>
              {index == 1 && (
                <option disabled>
                  ----------------------------------------------------------------
                </option>
              )}
              <option key={x.id} value={index}>
                {formatDate(x.requestedAt, "L hh:mm A")} -{" "}
                {"Order Verification"} - {x.response?.status}{" "}
              </option>
            </>
          ))}
        </select>
      </div>
      <div className="bonafide-result-container">
        <div className="bonafide-status-result-container">
          {bonafideSelectedResult?.response?.status && (
            <React.Fragment>
              <div className="bonafide-overview">
                <div className="status-label" style={{ alignItems: "center" }}>
                  <div>Transactions:</div>
                  <div
                    className={`status-text`}
                    style={{
                      color: getStatusTextColor(
                        bonafideSelectedResult?.response?.status
                      )
                    }}
                  >
                    {bonafideSelectedResult?.response?.status}
                  </div>
                </div>
              </div>
              <div className="status-label">
                <div style={{ fontSize: "1.05em" }}>Eligibility:</div>
                <div
                  className={`status-text`}
                  style={{
                    color:
                      bonafideSelectedResult?.response?.eligStatus ===
                      "InActivity"
                        ? "orange"
                        : "#222",
                    display: "flex",
                    alignItems: "flex-end",
                    fontSize: "1.15em"
                  }}
                >
                  {bonafideSelectedResult?.response?.eligStatus}
                </div>
              </div>

              {bonafideSelectedResult.response?.eligibility && (
                <div
                  style={{
                    marginLeft: "1.3em",
                    padding: 5
                  }}
                >
                  <div>
                    {bonafideSelectedResult.response?.eligibility["271Status"]}
                  </div>
                  <div>
                    {bonafideSelectedResult.response?.eligibility?.ebeligStatus}
                  </div>
                  <div>
                    {bonafideSelectedResult.response?.eligibility?.message}
                  </div>
                  {bonafideSelectedResult.response?.eligibility
                    ?.remainingDeductible && (
                    <div>
                      Remaining Deductible :{" "}
                      {
                        bonafideSelectedResult.response?.eligibility
                          ?.remainingDeductible
                      }
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <React.Fragment>
          <div className="bonafide-errors-container">
            <div className="reason-status-text">
              {bonafideSelectedResult?.response?.reason}
            </div>
            {bonafideSelectedResult.response?.errors &&
              bonafideSelectedResult.response?.errors
                .filter(e => !!e)
                .map(s => (
                  <div key={s} className="bonafide-issue">
                    {s}
                  </div>
                ))}
          </div>
          <div className="bonafide-eq-info">
            <PatientResponsibility
              patientResponsibility={
                bonafideSelectedResult?.response?.patientResponsibility
              }
            />
          </div>
        </React.Fragment>
      </div>
      <div className="bonafide-footer">
        {getBonafideInsuranceInfo.isFetching ? (
          <InlineWaiting />
        ) : (
          <React.Fragment>
            <i
              title="Refresh"
              className="refresh-button"
              onClick={() => getVerificationInfoAPI.fetchData(orderId)}
            >
              <MdRefresh />
            </i>
            <div className={`bonafide-request-order-info`}>
              {insuranceVerificationAPI.isFetching ? (
                <InlineWaiting />
              ) : (
                <button
                  className="step-label"
                  style={{ margin: 0, padding: 5 }}
                  onClick={() => insuranceVerificationAPI.fetchData(orderId)}
                >
                  Verify
                </button>
              )}
            </div>
            <HasRolePermission allowedRoles={["Administrator"]}>
              <DebugWindow bonafideSelectedResult={bonafideSelectedResult} />
            </HasRolePermission>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

BonafideInsuranceVerificationPanel.propTypes = {
  orderId: PropTypes.string.isRequired,
  errorMessage: PropTypes.func.isRequired,
  getBonafideInsuranceInfo: PropTypes.func.isRequired,
  companyInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string,
  visible: PropTypes.bool
};

export default connect(
  (state, { orderId }) => {
    const { data: orderLineItems } = selectors.getOrderLineItems(state, {
      orderId
    });

    return {
      orderLineItems
    };
  },
  {
    getBonafideInsuranceInfo,
    submitBonafideOrder,
    errorMessage
    //requestOrderHoldReasons
  }
)(BonafideInsuranceVerificationPanel);

const PatientResponsibility = ({ patientResponsibility }) => {
  if (!patientResponsibility) return null;

  const values = Object.keys(patientResponsibility);

  return (
    <table>
      <thead>
        <tr>
          <th>HCPCS</th>
          <th>Patient Responsibility</th>
        </tr>
      </thead>
      <tbody>
        {values.map(hcpc => (
          <tr key={hcpc}>
            <td>{hcpc}</td>
            <td>{formatMoney(patientResponsibility[hcpc])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

PatientResponsibility.propTypes = {
  patientResponsibility: PropTypes.shape({
    hcpcs: PropTypes.number
  })
};

const DebugWindow = ({ bonafideSelectedResult }) => {
  if (!bonafideSelectedResult) return null;
  return (
    <PureModal
      renderTrigger={({ openModal }) => (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            width: "100%"
          }}
        >
          <i title="Debug" className="refresh-button" onClick={openModal}>
            <MdBugReport />
          </i>
        </div>
      )}
    >
      <div style={{ padding: 10 }}>
        <h5>Request</h5>
        <pre>{JSON.stringify(bonafideSelectedResult.requestBody, null, 2)}</pre>
        <h5>Response</h5>
        <pre>
          {JSON.stringify(bonafideSelectedResult.responseBody, null, 2)}
        </pre>
      </div>
    </PureModal>
  );
};

DebugWindow.propTypes = {
  bonafideSelectedResult: PropTypes.object
};
